<template>
  <div class="vps-detail-view">
    <div v-if="!link && !error">
      <!--    <div v-if="isLoading && !error">-->
      <base-loader class="vps-info__loader-item" />
    </div>
    <!--        <div v-else-if="error">-->
    <!--    <base-empty-->
    <!--      v-else-if="error"-->
    <!--      :title="$t('empty.title')"-->
    <!--      :text="$t('empty.text')"-->
    <!--      :click="{ title: $t('update'), click: getVncConsoles() }"-->
    <!--      class="bitrix-info__empty"-->
    <!--    />-->
    <!--        </div>-->
    <div v-else>
      <base-input
        v-if="root"
        :value="root"
        size="medium"
        :disabled="true"
        :label="$t('root')"
        class="el-col-4"
      ></base-input>

      <transition name="bubble">
        <div v-if="link" class="l-col">
          <iframe :src="link" class="frame"></iframe>
        </div>
      </transition>
      <transition name="bubble"> </transition>
    </div>
  </div>
</template>

<script>
import { OPTariff } from '@/models/OP/OPTariff';
import BaseInput from '@/components/BaseInput/BaseInput';
import showErrorModal from '@/mixins/showErrorModal';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
export default {
  name: 'ServerVnc',
  components: {
    BaseInput,
    // BaseEmpty,
  },
  mixins: [showErrorModal],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
  },
  data() {
    return {
      link: '',
      error: '',
      isLoading: false,
    };
  },
  computed: {
    status() {
      return this.tariff.status.code;
    },
    root() {
      return this.tariff.adminPass ? this.tariff.adminPass : '';
    },
    id() {
      return this.tariff.id;
    },
  },
  mounted() {
    // console.log('--vnc--');
    // console.log(this.link);
    if (!this.link) this.getVncConsoles();
  },
  methods: {
    getVncConsoles() {
      this.isLoading = true;
      this.error = '';
      const params = {
        server: this.id,
        vnc: {
          remote_console: {
            protocol: 'vnc',
            type: 'novnc',
          },
        },
      };
      this.$store
        .dispatch('moduleStack/createVncConsole', params)
        .then(async data => {
          this.isLoading = false;
          this.link = data.remote_console.url;
        })
        .catch(e => {
          this.isLoading = false;
          this.error = '504 Gateway Time-out';
          // console.log(e);
          this.showError(e ? e : this.error);
          this.getVncConsoles();
        });
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "os": "Источник",
      "name": "Тип конфигурации",
      "cpu": "vCPU",
      "ram": "Память",
      "root": "Пароль root"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.frame {
  width: 100%;
  height: 50vh;
  margin-top: 2rem;
}
.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 160px;
      margin: 0.5em 0.75rem;
    }
  }
}
</style>
